.need-padding-for-uploading-product{
    padding: 120px 0;
}
.add-product.need-padding-for-uploading-product {
    width: 60%;
    margin: 0 auto;
}

.product-input {
    font-family: 'Montserrat';
}
.add-product.need-padding-for-uploading-product h1 {
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 35px;
    font-family: 'Montserrat';
}

.product-input {
    display: flex;
    justify-content: space-between;
}
input.margin-right {
    margin-right: 24px;
    display: inline-block;
}

.product-input input {
    width: 100%;
    padding: 14px;
    margin-bottom: 32px;
    border: 1px solid #DDD;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
}

.product-single-input input{
    width: 100%;
    padding: 14px;
    margin-bottom: 32px;
    border: 1px solid #DDD;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
    font-family: 'Montserrat';

}
.product-single-input select{
    width: 100%;
    padding: 14px;
    margin-bottom: 32px;
    border: 1px solid #DDD;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
    font-family: 'Montserrat';
    cursor: pointer;
}
.product-single-input textarea{
    width: 100%;
    padding: 14px;
    margin-bottom: 32px;
    border: 1px solid #DDD;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
    font-family: 'Montserrat';
    cursor: pointer;
}

div#createProductFormFile input::file-selector-button {
    margin: 0;
    text-align: center;
    width: 100%;
    padding: 0;
    border: 1px solid #DDD;
    padding: 15px;
    background: none;
    outline: none;
    z-index: 99 !important;
    color: #000000;
    text-transform: uppercase;
    outline: none;
    font-family: 'Montserrat';
    font-size: 12px;
    cursor: pointer;
    transition: .3s ease-in-out;
  }

  div#createProductFormFile input::file-selector-button:hover{
      background: black;
      color: #FFF;
  }

  .product-parent-div {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: center;
    }

    .product-image-details img {
        width: 117px;
        height: 117px;
        object-fit: contain;
        margin-top: 36px;
    }

    .add-product.need-padding-for-uploading-product button {
        width: 100%;
        padding: 15px;
        outline: none;
        border: 1px solid rgb(0, 0, 0);
        background: black;
        font-family: 'Montserrat';
        margin-top: 36px;
        font-size: 13px;
        color: white;
        transition: .3s ease-in;
    }


    .add-product.need-padding-for-uploading-product button:hover{
        background: none;
        color: black;
    }
    span.MuiSlider-track {
        left: -4% !important;
        width: 100% !important;
    }
    .PrivateValueLabel-offset-3 {
        top: -34px;
        z-index: 1;
        position: absolute;
        font-size: 0.75rem;
        transform: scale(0);
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.01071em;
        transform-origin: bottom center;
        left: -42px !important;
    }

    span.PrivateValueLabel-label-5 {
        text-align: center !important;
        position: relative !important;
        left: -7px !important;
    }

    .MuiSlider-thumb {
        width: 12px;
        height: 12px;
        display: flex;
        outline: 0;
        position: absolute;
        box-sizing: border-box;
        margin-top: -5px;
        transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        border-radius: 50%;
        margin-left: 5px;
        justify-content: center;
        background-color: currentColor;
    }

    .PrivateValueLabel-circle-4 {
        width: 32px;
        height: 32px;
        display: flex;
        transform: rotate(-45deg);
        align-items: center;
        border-radius: 50%  !important;
        background-color: currentColor;
        justify-content: center;
    }

    @media only screen and (max-width: 990px)  {
        .add-product.need-padding-for-uploading-product {
            width: 95%;
            margin: 0 auto;
        }
    }

    .product-upload-input input {
        width: 100%;
        padding: 14px;
        border: 1px solid #DDD;
        color: #767676;
        font-family: 'Spartan';
        font-size: 12px;
        outline: none;
        margin-bottom: 29px;
        text-align: center;
        text-transform: uppercase;
    }
    .product-upload-input select {
        width: 100%;
        padding: 16px;
        border: 1px solid #DDD;
        color: #767676;
        font-family: 'Spartan';
        font-size: 10px;
        outline: none;
        margin-bottom: 29px;
        text-transform: uppercase;
        text-align: left;
    }
    .product-upload-input textarea {
        width: 100%;
        padding: 14px;
        border: 1px solid #DDD;
        color: #767676;
        font-family: 'Spartan';
        font-size: 11px;
        outline: none;
        margin-bottom: 29px;
        text-transform: uppercase;
        text-align: left;
    }