.paymentContainer {
  display: grid;
  place-items: center;
  background-color: #fff;
  min-height: 70vh;
  margin: 2vmax auto;
  padding: 2vmax;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
}

.paymentForm {
  width: 40%;
  height: auto;
  background-color: #f9f9f9;
  padding: 2vmax;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.paymentForm > p {
  font: 400 1.8vmax "Spartan", sans-serif;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1vmax;
  text-align: center;
  width: 70%;
  margin: 0 auto 2vmax;
}

.paymentInput {
  padding: 1.3vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  font-family: 'Spartan', sans-serif;
}

.paymentForm > div {
  display: flex;
  align-items: center;
  margin: 1.5vmax 0;
  position: relative;
}

.paymentForm > div > svg {
  position: absolute;
  left: 10px;
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.6);
}

.paymentContainer button {
  padding: 15px;
  width: 100%;
  border: 2px solid black;
  background: black;
  color: white;
  font-family: 'Spartan', sans-serif;
  font-size: 1.2vmax;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.paymentContainer button:hover {
  background: white;
  color: black;
}

.bank-details {
  background-color: #f1f1f1;
  padding: 1.5vmax;
  border-radius: 4px;
  margin-bottom: 1.5vmax;
  font-family: 'Spartan', sans-serif;
  color: #333;
}

.bank-details h4 {
  margin-bottom: 0.5vmax;
  font-weight: 600;
}

.bank-details p {
  margin-bottom: 0.5vmax;
}

@media screen and (max-width: 990px) {
  .paymentForm {
    width: 90%;
  }

  .paymentInput {
    padding: 3vmax 1.5vmax;
  }

  .paymentForm > div {
    margin: 1vmax 0;
    justify-content: space-between;
  }

  .paymentForm > div > svg {
    left: 5px;
    font-size: 2.5vmax;
  }

  .paymentContainer button {
    padding: 12px;
    font-size: 1.5vmax;
  }
}

.payment_success_item h1 {
  font-family: 'Spartan', sans-serif;
  font-size: 2.2vmax;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2vmax;
  margin-top: 1.5vmax;
}
