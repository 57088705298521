
.container {
    margin: 0 auto;
    width: 1280px!important;
}
.contact{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    position: relative;
    z-index: 99 !important;
    }
    .contact::before {
        content: "contact";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 100px;
        font-size: 116px;
        font-family: 'Lora';
        text-transform: uppercase;
        font-weight: 700;
        color: #fafafafa;
        z-index: 1 !important;
    }

    .contact-section {
        padding: 162px 50px;
        padding-bottom: 10px !important;
        padding-right: 0 !important;
    }
    

    .contact-img img {
        height: auto;
        overflow: hidden;
        max-width: 100%;
        object-fit: contain;
    }

    .contact-section h1 {
        font-size: 53px;
        font-family: 'Poppins';
        text-transform: capitalize;
        font-weight: 900;
        padding: 16px 5px;
        margin-bottom: 44px;
    }
    .contact-section input, .contact-section textarea {
        border: 1px solid #ddd;
        font-family: 'Montserrat';
        font-size: 12px;
        outline: none;
        text-transform: uppercase;
        width: 100%;
        text-align: inherit;
        padding: 18px;
        margin-bottom: 20px;
    }

    .contact-section {
    padding: 162px 0px;
    padding-bottom: 10px !important;
    padding-right: 0 !important;
}
    .contact-section textarea {
        width: 100%;
        font-family: 'Spartan';
        font-size: 11px;
        text-transform: uppercase;
        padding: 16px;
        border: 1px solid #ddd;
        margin-bottom: 20px;
        outline: none;
    }


    .contact-section button {
        margin-bottom: auto;
        padding: 18px 56px;
        background: black;
        border: 1px solid #000;
        color: aliceblue;
        font-family: 'Spartan';
        font-size: 11px;
        text-transform: uppercase;
        transition: .3s ease-in-out;
    }

    .contact-section button:hover{
        background:none;
        color: rgb(4, 4, 5);
    }
  
    @media only screen and (max-width: 990px)  {
        .container {
            margin: 0 auto;
            width: 100%!important;
        }
        .contact{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            position: relative;
            z-index: 99 !important;
            }
          
        
            .contact-img img {
                height: 100%;
                overflow: hidden;
                width: 100%;
                object-fit: contain;
            }
            .contact-section {
                padding: 50px;
                padding-bottom: 50px !important;
                padding-right: 50px !important;
            }
            .content-of-shop h1 {
                font-size: 32px;
                font-weight: 800;
                text-align: center;
                color: #6a6767;
                font-family: 'Spartan';
                text-transform: uppercase;
                line-height: 69px;
            }

            .contact::before {
                content: "contact";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 0 100px;
                font-size: 116px;
                font-family: 'Lora';
                text-transform: uppercase;
                font-weight: 700;
                color: #fafafafa;
                z-index: 1 !important;
                display: none;
            }
        }

        a.linksReactNavbar {
            font-family: 'poppins' !important;
            font-size: 16px !important;
            text-transform: uppercase;
        }