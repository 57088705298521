@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.shipping-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 70px;
    grid-row-gap: 0px;
    padding: 0 70px;
}

.left-side-information input {
    width: 100%;
    border-bottom: 1px solid #a2a2a2 !important;
    outline: none;
    border: none;
    padding-bottom: 10px;
    font-family: 'Montserrat';
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 500;
    color: #767676;
}

    .left-side-information label {
        font-family: 'Montserrat';
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 12px;
    }

    h1.shipping {
        font-family: 'Montserrat';
        font-size: 26px;
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 17px;
    }

    p.font-family {
        font-family: 'Montserrat';
        font-weight: 500;
    }

    .need-custom-padding {
        padding: 0 40px;
    }

    @media only screen and (max-width: 990px)  {
        .shipping-information {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 22px;
            grid-row-gap: 0px;
            padding: 0 40px;
            }
            .shipping-information {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 22px;
                grid-row-gap: 0px;
                padding: 0 40px;
                }
    }
