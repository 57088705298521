

.user___password___update{
    background: url('https://i.ibb.co/4JTbXp8/aiony-haust-IXYxq-P4zejo-unsplash.jpg') fixed top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 5;
}

.user___password___update::after{
    content: '';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.856);
    z-index: 1;
}

.user___password___update form {
    z-index: 9;
    position: relative;
    width: 100%;
    padding: 0 369px;
}

@media only screen and (max-width: 990px)  {
    .user___password___update form {
        z-index: 9;
        position: relative;
        width: 100%;
        padding: 0 40px;
    }  
}

.user___password___update form input {
    width: 100%;
    margin-bottom: 44px;
    padding: 17px;
    background: none;
    border: 1px solid #a9a4a4;
    font-family: 'Spartan';
    font-size: 13px;
    outline: none;
}
.user___password___update form button {
    padding: 20px 42px;
    background: none;
    border: 1px solid rgb(0, 0, 0);
    color: rgb(0, 0, 0);
    font-family: 'Spartan';
    font-size: 12px;
    transition: .3s ease-in-out;
}

.user___password___update form button:hover{
    background: black;
    color: rgb(255, 255, 255);
    
}

.user___password___update form h1 {
    padding-bottom: 31px;
    font-family: 'Spartan';
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    color: #2b2b2b;
}