* {
    box-sizing: border-box;
  }
  

  
  /* button:active {
    transform: scale(0.95);
  }
  
 */
  
 .form-container > div {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    
  }
.sign-up-container > div {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
 
  }


  
  .login-signUp {
    background-color: rgb(255, 255, 255);
    border-radius: none;
    box-shadow: none;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }
  
  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }
  
  .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }
  
  .login-signUp.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }
  
  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }
  
  .login-signUp.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }
  
  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }
  
    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }
  
  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 2;
  }
  
  .login-signUp.right-panel-active .overlay-container {
    transform: translateX(-100%);
  }
  
  .overlay {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .login-signUp.right-panel-active .overlay {
    transform: translateX(50%);
  }
  
  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .overlay-left {
    transform: translateX(-20%);
    background:linear-gradient(rgba(0, 0, 0, 0.576), rgba(0, 0, 0, 0.445)),url('https://i.ibb.co/8Mw5rp6/bg-image-11-1.jpg')no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100vh;
  }
  
  .login-signUp.right-panel-active .overlay-left {
    transform: translateX(0);
  }
  
  .overlay-right {
    right: 0;
    transform: translateX(0);
    background:linear-gradient(rgba(0, 0, 0, 0.576), rgba(0, 0, 0, 0.445)), url('https://i.ibb.co/1myZ2wc/about-5.png')no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100vh;
  }
  
  .login-signUp.right-panel-active .overlay-right {
    transform: translateX(20%);

  }

  .overlay-panel button {
    background: none;
    border: 1px solid #FFFF;
    padding: 19px 58px;
    color: #FFF;
    font-family: 'Spartan';
    font-size: 12px;
    transition: .3s ease-in-out;
}
.overlay-panel button:hover{
    background:rgba(255, 255, 255, 0.316) ;
    border: none !important;
    color: black;
}
.form-container.sign-in-container h1 {
    font-family: 'Spartan';
    text-transform: capitalize;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: 500;
}

.form-container.sign-in-container input {
    width: 100%;
    margin-bottom: 27px;
    padding: 16px;
    text-transform: lowercase;
    font-family: 'Spartan';
    font-size: 11px;
    border: 1px solid #DDD;
    outline: none;
}

.form-container.sign-in-container input::placeholder{
    font-family: 'Spartan';
    font-size: 11px;
    text-transform: uppercase;
}

.form-container.sign-in-container button {
    background: black;
    border: none;
    color: #FFF;
    font-family: 'Spartan';
    font-size: 11px;
    padding: 17px 48px;
    text-transform: capitalize !important;
    transition: .3s ease-in-out;
}

.form-container.sign-in-container button:hover{
    background: rgb(48, 48, 48);
}

.form-container.sign-up-container h1{
    font-family: 'Spartan';
    text-transform: capitalize;
    font-size: 30px;
    margin-bottom: 24px;
    font-weight: 500;
}

.form-container.sign-up-container input{
    width: 100%;
    margin-bottom: 27px;
    padding: 16px;
    text-transform: lowercase;
    font-family: 'Spartan';
    font-size: 11px;
    border: 1px solid #DDD;
    outline: none;
}
.form-container.sign-up-container input::placeholder{
    font-family: 'Spartan';
    font-size: 11px;
    text-transform: uppercase;
}


.form-container.sign-up-container button {
    background: black;
    border: none;
    color: #FFF;
    font-family: 'Spartan';
    font-size: 11px;
    padding: 17px 48px;
    text-transform: capitalize !important;
    transition: .3s ease-in-out;
}

.form-container.sign-up-container button:hover{
    background: rgb(48, 48, 48);
}
.form-container.sign-up-container {
    padding: 0 41px;
}

@media only screen and (max-width: 990px)  {
  .form-container > div {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 14px;
    height: 100%;
}

    .overlay-panel button {
        background: none;
        border: 1px solid #FFFF;
        padding: 19px 30px;
        color: #FFF;
        font-family: 'Spartan';
        font-size: 8px;
    }
    .form-container.sign-up-container h1 {
        font-family: 'Spartan';
        text-transform: capitalize;
        font-size: 22px;
        margin-bottom: 24px;
        font-weight: 600;
    }
    .form-container.sign-up-container {
        padding: 0 10px;
    }
    .video-overly h1 {
        font-family: 'Sacramento';
        font-size: 37px;
        padding: 22px;
        color: #fffdfe;
        opacity: 0.6;
        display: none !important;
    }
    .parent-of-categories-product {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        z-index: 1;
    }
    .sidebar___section {
        width: 40%;
        border-right: 1px solid #DDD;
        height: 100vh;
        background-attachment: fixed;
        padding: 22px;
        position: sticky;
        top: 2px;
    }
    .product____section {
        width: 60% !important;
    }

    .inStock__Status h6 {
        display: none;
    }

    .add_to_cart_button button {
        padding: 17px 25px;
        font-size: 11px;
        border: 1px solid black;
        background: black;
        color: #FFF;
        box-sizing: border-box;
        transition: .3s ease-in-out;
    }

    .product-introduce span {
        font-size: 9px;
        color: #767676;
        display: none;
    }
    .product-introduce h1 {
        font-size: 16px;
        text-transform: uppercase;
    }
    .inStock__Status p {
        margin-top: 1vh;
        text-transform: lowercase;
        font-size: 13px;
        line-height: 1.3rem;
        color: #000000;
    }

    .inStock__Status button {
        padding: 17px 50px;
        font-size: 11px;
        border: 1px solid black;
        background: black;
        color: #FFF;
        box-sizing: border-box;
        transition: .3s ease-in-out;
        text-transform: uppercase;
        margin-top: 0vh;
    }


    .display-none-when-media-query-active {
        display: none;
    }
    .parent {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        font-family: 'Spartan', sans-serif !important;
    }
    .products__Item__text h1 {
        text-align: center;
        font-size: 42px;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 32px;
    }
    .no-match h1 {
        font-size: 44px;
        position: relative;
        z-index: 9 !important;
        color: #ffffff6b;
        font-family: 'Poppins';
        font-weight: 700;
    }
}


@media only screen and (max-width: 600px)  {
  div#registerImage input {
    width: 69% !important;
}
  .overlay-panel button {
    background: none;
    border: 1px solid #FFFF;
    padding: 19px 19px;
    color: #FFF;
    font-family: 'Spartan';
    font-size: 6px;
}
.form-container.sign-up-container h1 {
  font-family: 'Spartan';
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: 600;
}

    li.li-flex {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font-family: 'Poppins';
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        transition: .3s ease-in-out;
    }
    .categories h2 {
        font-size: 12px;
        padding: 9px 0;
        font-weight: 700;
        font-family: 'Poppins';
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .sidebar___section h1 {
        font-size: 12px;
        text-align: center;
        padding: 22px 0;
        font-family: 'Poppins';
        font-weight: 200;
        border-bottom: 1px solid #DDD;
        margin: 0 auto;
        color: #767676;
    }


}

.css-1wnsr1i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border: 2px solid #0000 !important;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 32px;
}

.login-n-forgot-password {
  text-align: end;
}

.login-n-forgot-password a {
  font-family: 'Spartan';
  font-size: 11.5px;
  color: black;
  position: relative;
  bottom: 20px;
  text-decoration: none;
}

button.button-customize {
  position: relative;
  bottom: 16px;
}

div#registerImage {
  display: flex;
  justify-content: center;
}

div#registerImage img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
}
div#registerImage input::file-selector-button {
  border: none !important;
  cursor: pointer !important;
}

div#registerImage {
  display: flex;
  justify-content: space-between;
}
div#registerImage input::file-selector-button {
  margin: 0;
  text-align: center;
  width: 100%;
  padding: 0;
  border: 1px solid #DDD;
  padding: 15px;
  background: none;
  outline: none;
  z-index: 99 !important;
  color: #000000;
  
}

div#registerImage input {
  padding: 0;
}
.create-account {
  display: flex;
  justify-content: space-between;

}

div#registerImage img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  line-height: 40px;
  object-fit: cover;
}

div#registerImage input {
  width: 40%;
}

div#registerImage img {
  height: 48px;
  width: 48px;
  line-height: 40px;
  object-fit: cover;
  border-radius: inherit;
  border-radius: 50%;
}

.create-account img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.create-account img {
  position: relative;
  bottom: 10px;
  right: 3px;
}