.video-section {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 100px 0;
  background: black;
  z-index: 2;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
  
  .video-section video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }
  .text {
    position: relative;
    z-index: 999;
  }
  
  .video-overly {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    z-index: 888;
}

  /* div#root {
    overflow: hidden;
} */

.text-section {
    position: relative;
    width: 100%;
}

.text-section h3 {
    font-size: 55px;
    font-weight: 300;
    color: #ffffffa6 !important;
    position: relative;
    z-index: 9999;
    font-family: 'Lora';
    text-transform: uppercase;
    padding-bottom: 20px;
}

.text-section h1 {
    color: #ffffffa6 !important;
    position: relative;
    z-index: 9999;
    font-size: 146px;
    text-transform: uppercase;
}

.text-section h2 {
    font-size: 31px;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffffa6 !important;
    position: relative;
    z-index: 9999;
}



.text-section {
    position: relative;
    width: 100%;
    padding: 100px 0;
}

.text-section {
  position: relative;
  width: 100%;
  padding: 100px 0;
  z-index: 9999 !important;
}