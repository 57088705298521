.products___item h1 {
    text-align: center;
    padding: 48px 0;
    font-family: 'Spartan';
    text-transform: uppercase;
    font-weight: 300;
    font-size: 23px;
    padding-top: 120px;
}
.MuiIconButton-root:hover {
    background-color: none !important;
}
