.cart{
    background:url('https://i.ibb.co/QbVNYXR/slider-1.jpg') fixed top center;
    background-repeat:no-repeat;
    background-size:cover;
    height: 80vh;
    z-index: 1;
    overflow: hidden;

}

.cart::before{
    content:"";
    position:absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cart-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    position: relative;
    top: 40px;
}

.cart-text h1{
    width: 100%;
    text-align: left;
    padding: 0 20px
}

.cart-text h1 {
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Poppins';
}

.cart-text p {
    width: 100%;
    font-size: 20px;
    padding: 0 20px;
    font-family: 'Spartan';
    margin-top: 12px;
}

.cart-text button {
    padding: 18px 40px;
    border: 1px solid black;
    font-family: 'spartan';
    font-size: 11px;
    text-transform: uppercase;
    color: #FFF;
    background: black;
    transition: .3s ease-out;
}
.cart-text button:hover {
    padding: 18px 40px;
    border: 1px solid black;
    font-family: 'spartan';
    font-size: 11px;
    text-transform: uppercase;
    color: rgb(0, 0, 0);
    background:none;
}

.cart-section p {
    font-family: 'Spartan';
    font-size: 11px;
    color: #767676;
}

.cart-section h1 {
    font-size: 35px;
    font-weight: 400;
    font-family: 'Spartan';
}

.product-sending-parent-section {
    padding: 92px 0;
    margin-top: 49px;
    border-top: 1px solid #dddddd69;
    padding-top: 0;
}

.user-cart img {
    height: 100px;
    width: 100px;
    object-fit: contain;
}

.user-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDD;
    padding: 25px 0;
}

.user-cart h6 {
    font-family: 'Spartan';
    font-size: 12px;
    font-weight: 600;
    color: #484848;
}

.user-cart p {
    font-size: 14px;
    font-weight: 500;
}

.user-cart input {
    text-align: center;
    border: 1px solid #DDD;
    color: #606060;
    font-family: 'Spartan';
    font-size: 12px;
    padding: 6px;
    outline: none;
}

.quantity {
    display: flex;
    justify-content: center;
    align-items: center;
}


.quantity button {
    background: none;
    border: 1px solid #DDD;
    padding: 3px 10px;
    margin: 10px;
    font-size: 16px;
}

@media only screen and (max-width: 990px)  {
    .user-cart {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #DDD;
        padding: 25px 0;
    }
    .user-cart img {
        height: 100px;
        width: 100px;
        object-fit: contain;
        margin-bottom: 20px;
    }

    .user-cart p {
        font-size: 14px;
        font-weight: 500;
        margin-top: 13px;
    }

    .user-cart span svg {
        font-size: 23px;
        color: #767676;
        cursor: pointer;
        margin-bottom: 20px;
    }
}

.user-cart h6 {
    font-family: 'Spartan';
    font-size: 12px;
    font-weight: 600;
    color: #484848;
    width: 190px;
    line-height: 28px;
    text-transform: uppercase;
}

.cart-price {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    font-family: "spartan";
}

.checkout button {
    width: 100%;
    border: 1px solid #000;
    padding: 17px;
    text-transform: uppercase;
    font-family: "Spartan";
    font-size: 12px;
    font-weight: 400;
    background: black;
    color: #FFF;
    transition: .3s ease-in-out;
    position: relative;
    top: 38px;
}

.checkout button:hover{
    background: none;
    color: rgb(0, 0, 0);
}


.user-cart span svg {
    font-size: 23px;
    color: #767676;
    cursor: pointer;
}


.empty_cart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
}

.empty_cart h1 {
    padding: 19px 0;
    font-family: 'Poppins';
    font-weight: 800;
    text-transform: uppercase;
    font-size: 55px;
}

.empty_cart span svg {
    font-size: 70px;
}
.empty_cart button {
    font-size: 12px;
    padding: 21px 80px;
    font-family: 'Spartan';
    text-transform: uppercase;
    background: black;
    border: 1px solid black;
    color: #FFFF;
    transition: .3s ease-in-out;
    margin-top: 10px;
}

.empty_cart button:hover{
    background: none;
    color: #000;
}

@media only screen and (max-width: 990px)  {

    .empty_cart button {
        font-size: 10px;
        padding: 21px 30px;
        font-family: 'Spartan';
        text-transform: uppercase;
        background: black;
        border: 1px solid black;
        color: #FFFF;
        transition: .3s ease-in-out;
        margin-top: 10px;
    }

    .empty_cart span svg {
        font-size: 70px;
    }

    .empty_cart h1 {
        padding: 19px 0;
        font-family: 'Poppins';
        font-weight: 800;
        text-transform: uppercase;
        font-size: 23px;
        text-align: center;
    }
}